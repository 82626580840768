import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import forms from './appData'
import settings from './settings'
import library from './library'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		user,
		settings,
		forms,
		library,
	},
	state: {
		instances: [],
		instance: null,
		hives: [],
		cats: [],
		uploadDialog: false,
		folders: [],
		files: {},
		currentFolder: '/',
		theApp: {},
		formType: 'All Form/Survey Templates',
		prevRoute: '/',
		rightbar: '',
		save: '',
		search: {
			form: {}
		},

		currentForm: {
			leadScore: 1,
			name: '',
			type: '',
			pageButtons: [],
			url: '',
			instance: '',
			tags: [],
			fields: [
				{
					type: 'email',
					id: Date.now() + '',
					label: 'Email',
					placeholder: '',
					required: true
				},
				{
					id: Date.now() + 1,
					hide: false,
					type: 'name',
					label: 'Name',
					required: false,
					middleInitial: false,
					prefix: false,
					suffix: false,
					icon: `<svg xmlns="http://www.w3.org/2000/svg" style="margin-top: 10px;" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user mr-2"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>`
				},
				{
					id: Date.now() + 2,
					hide: false,
					type: 'phone',
					label: 'Phone Number',
					placeholder: '',
					required: false,
					format: 'National',
					country: '',
					default: '',
					icon: `<svg xmlns="http://www.w3.org/2000/svg" style="margin-top: 10px;" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone mr-2"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>`
				},
				{
					id: Date.now() + 3,
					hide: false,
					type: 'address',
					label: 'Address',
					required: false,
					country: '',
					options: {
						addressOne: true,
						addressTwo: true,
						city: true,
						province: true,
						postal: true,
						country: false
					},
					icon: `<svg xmlns="http://www.w3.org/2000/svg" style="margin-top: 10px;" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin mr-2"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>`
				},
				{
					id: Date.now() + 4,
					hide: false,
					type: 'isAgent',
					displayType: 'checkbox',
					label: 'Are you a realtor?',
					required: false,
					default: '',
					options: [
						{ option: 1 },
						{ option: 0 }
					],
					icon: `<svg xmlns="http://www.w3.org/2000/svg" style="margin-top: 10px;" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin mr-2"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>`
				}
			],
			lastRegistered: 0,
			users: [],
			options: {
				feedChildren: true,
				submitLabel: 'Submit',
				thanks: {
					headline: '',
					text: ''
				},
				supporting: '',
				consent: {
					enabled: true,
					text: 'I consent.'
				},
				smsConsent: {
					enabled: false,
					text: 'By submitting this form and signing up for texts, you consent to receive marketing text messages from [company name] at the number provided. Consent is not a condition of purchase. Msg & data rates may apply.'
				},
				autoresponder: '',
				autoresponderSubject: '',
				autoresponderPreheader: '',
				fromEmail: ''
			}
		},
		fileStructure: []
	},
	mutations: {
		SET_FILE_STRUCTURE(state, data) {
			state.fileStructure = data;
		},
		PREVIOUS_ROUTE(state, data) {
			state.prevRoute = data
		},
		SEARCH_SELECT(state, data) {
			state.search.form = data
		},
		SAVE_AND_CLOSE(state, data) {
			state.save = Date.now() + ''
		},
		OPEN_RIGHT_BAR(state, data) {
			state.rightbar = data
		},
		CLOSE_RIGHT_BAR(state) {
			state.rightbar = ''
		},
		FORM_TYPE(state, data) {
			state.formType = data.value
		},
		PAGE_BUTTONS(state, buttons) {
			state.pageButtons = buttons
		},
		OPEN_FORM_PAGE(state, data) {
			// state.formType = 'Add New Form'
			state.currentForm = data
		},
		OPEN_EDIT_FORM_PAGE(state, data) {
			// state.formType = 'Edit Form'
		},
		SET_APP(state, data) {
			state.theApp = data
		},
		cancelUpload: (state) => {
			state.uploadDialog = false
		},
		uploadDialog: (state, folders = []) => {
			state.uploadDialog = true
			state.folders = folders
		},
		SET_INSTANCE: (state, data) => {
			state.instance = data
			state.cats = data.activeApps
		},
		SET_INSTANCES: (state, data) => {
			state.instances = data
		}

	},
	getters: {
		isPremiumPackage: state => {
			return state && state.instance && state.instance.package && ["PA-COM-LRP", "PA-CON-HRP"].includes(state.instance.package.itemCode)
		}
	},
	actions: {



	},
})
