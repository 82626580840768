import Vue from 'vue'
import Vuex from 'vuex'
import { $api } from 'bh-mod'
import {setProp, deleteProp} from 'bh-mod'
Vue.use(Vuex)

export default {
    state: {
        loadingPage:false,
        newStoreTime:Date.now(),
        getStarted: false,
        tags:{},
        allForms:{},
        crmUsers:[],
        formDrawer: {
            show:false,
            type:'add',
            form:''
        },
        preview:{
            prev:''
        },
        statuses:{
            'bh_lead':{
                name:'Lead',
                color:'#53dfaf',
                id:'bh_lead',
            },
        },
        fields:{},
        realtors: []
    },
    mutations: {
        SET_SETTINGS(state, data){
            let temp = data
            if (data.userApp == null) {
                temp.userApp = {
                options:{
                    seenIntro:false,
                }
                }
                state.allSettings = temp
            } else {
                state.allSettings = data
            }
        },
		UPDATE_ALLSETTINGS(state, data) {
            state.allSettings.app = data
        },
        PREVIEW_FORM(state){
            state.preview.prev = Date.now() + ''
        },
        UPDATE_FORMS(state,data){
            delete state.allForms[data.id]
        },
        LOAD_PAGE: (state,status = !state.loadingPage) => state.loadingPage = status,
        SET_PROP: (state,{where,what,del = false}) => {
            if (del) return deleteProp(state,where)

            if (what.status === null) what.status = 'bh_lead'
            else if (typeof what.status === 'object') what.status = what.status.id

            if (what.tags && what.tags.length) {

                what.tags = what.tags.map(x => {
                    if (!state.tags[x.id] && typeof x === 'object') setProp(state,['tags',x.id],x)
                    return x.id
                })
            }


            state.newStoreTime = Date.now()
            setProp(state,where,what)
        },
        CLOSE_DRAWERS: state => {
            state.formDrawer.show = false
        },
        SHOW_ADD_FORM: (state, data)=> {
            state.formDrawer = {
                show:true,
                type:'add',
                form:''
            }
        },
        SHOW_EDIT_FORM: (state, form)=> {
            if (form.hasOwnProperty('id')){
                state.formDrawer = {
                    show:false,
                    type:'edit',
                    form:form.id
                }
            } else {
                state.formDrawer = {
                    show: false,
                    type:'add',
                    form:''
                }
            }
            state.preview.form = form
        },
        SET_APPDATA(state, {customFields = [], leadStatuses = [], tags = [], forms = [],users = []}) {

            customFields.forEach(x => state.fields[x.id] = x)
            leadStatuses.forEach(x => state.statuses[x.id] = x)
            tags.forEach(x => state.tags[x.id] = x)
            state.crmUsers = users

            forms.forEach(({contacts=0,lastRegistered = 0,...x}) => {
                x.contacts = contacts
                if (!x.entries) x.entries = 0
                x.lastRegistered = lastRegistered
                x.updatedAt = new Date(x.updatedAt).getTime()
                state.allForms[x.id] = x
            })
        },
        GET_STARTED: state => {
            state.getStarted = true
        },
		SET_REALTORS(state, data) {
			state.realtors = data;
		},
    },
    actions: {
        SET_APPDATA: ({commit},data) => commit('SET_APPDATA',data),
        CLOSE_FORM_DRAWER: ({commit},{type,data}) => {
            if (type !== 'click') commit('SET_PROP', {where:['allForms',data.id], what:data, del:type === 'delete'})
            commit('CLOSE_DRAWERS')
        },
		async FETCH_REALTORS({commit}) {
			try {
				let { data } = await $api.get(`/instances/:instance/users`);
				if (data.users && data.users.length) {
					let users = JSON.parse(JSON.stringify(data.users));
					users = users.filter(u => u.role && u.role.name === 'bh_agent').map(u => u.user);
					commit("SET_REALTORS", users);
				}
			} catch (error) {
				// Error handling
			}

		},
    },
}
