var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.instance)?_c('div',{class:_vm.$style.subbar,staticStyle:{"background-color":"#f7f5f9"}},[_c('div',{staticClass:"dF aC mb-0"},[_c('div',{class:_vm.$style.breadcrumbs,staticStyle:{"align-items":"center","display":"block !important"}},[_c('a-breadcrumb',[_c('a-breadcrumb-item',[_vm._v(_vm._s(_vm.instance.name))]),_c('a-breadcrumb-item',[_vm._v(_vm._s(_vm.selectedAppInitial))]),(_vm.$route.params.id)?_c('a-breadcrumb-item',{staticStyle:{"color":"var(--orange)"}},[_vm._v("Edit Form/Survey")]):(_vm.$route.path.includes('add'))?_c('a-breadcrumb-item',{staticStyle:{"color":"var(--orange)"}},[_vm._v("Add New Form/Survey")]):_c('a-breadcrumb-item',{staticStyle:{"color":"var(--orange)"}},[_vm._v(_vm._s(_vm.formType))])],1)],1),_c('div',{staticClass:"ml-auto d-none d-sm-flex",class:_vm.$style.amount},[(
                            !_vm.$route.params.id &&
                            !_vm.$route.path.includes('add') &&
							!_vm.$route.path.includes('settings')
                        )?[_c('a-auto-complete',{staticClass:"mr-4",staticStyle:{"width":"200px"},attrs:{"placeholder":" Search for a form...","dropdown-match-select-width":false,"dropdown-style":{ width: '200px' },"option-label-prop":"value","size":"large"},on:{"search":_vm.handleSearch}},[_c('template',{slot:"dataSource"},_vm._l((_vm.searchResult),function(form,formI){return _c('a-select-option',{key:form + formI,attrs:{"value":form.name + ` (${formI})`},on:{"click":function($event){return _vm.optionClicked(form)}}},[_c('div',{staticClass:"dF aC"},[_c('div',{staticClass:"f1"},[_c('div',[_c('strong',[_vm._v(_vm._s(form.name))])]),_c('small',{staticClass:"text-sm"},[_vm._v(_vm._s(form.type))])])])])}),1),_c('a-input',[_c('a-icon',{staticStyle:{"stroke":"#000","stroke-width":"34"},attrs:{"slot":"prefix","type":"search"},slot:"prefix"})],1)],2),_c('div',{staticClass:"mr-4 d-none d-xl-block dash-divider"})]:_vm._e(),(
                            !_vm.$route.params.id &&
                            !_vm.$route.path.includes('add') &&
							!_vm.$route.path.includes('settings')
                        )?_c('a-button',{attrs:{"type":"primary","icon":"plus","size":"large"},on:{"click":function($event){return _vm.$store.commit('SHOW_ADD_FORM')}}},[_vm._v(" ADD NEW ")]):(!_vm.$route.path.includes('settings'))?[_c('a-button',{staticClass:"mr-3 preview-button",attrs:{"size":"large"},on:{"click":function($event){return _vm.$store.commit('PREVIEW_FORM')}}},[_vm._v(" PREVIEW ")]),_c('a-button',{attrs:{"type":"primary","size":"large"},on:{"click":function($event){return _vm.$store.commit('SAVE_AND_CLOSE')}}},[_vm._v(" SAVE ")])]:_vm._e()],2)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }