<template>
	<div style="height: 100%">
		<LeftNavBar :title="selectedAppInitial" :hasChild="false" :items="sidebarItems" @opened="selectFormType"
			:selectedValue="$route.path == '/'
				? 'All Form/Survey Templates'
				: $route.path == '/forms'
					? 'Form Templates'
					: $route.path == '/surveys'
						? 'Survey Templates'
						: $route.path == '/walk-in'
							? 'Walk-In Templates'
							: $route.path == '/worksheets'
								? 'Worksheet Templates'
								: $route.path == '/settings' ? 'Settings' : ''
				" />
	</div>
</template>

<script>
import LeftNavBar from "bh-mod/components/common/LeftNavBar";
export default {
	components: { LeftNavBar },
	name: "menu-left",
	data() {
		return {};
	},
	computed: {
		selectedAppInitial() {
			return (
				(this.$store.state.theApp &&
					this.$store.state.theApp.media &&
					this.$store.state.theApp.media.initial) ||
				"FSC"
			);
		},

		instance() {
			return this.$store.state.instance
		},

		sidebarItems() {
			let items = [
				{
					label: "All Form/Survey Templates",
					value: "All Form/Survey Templates",
					iconUrl: require("@/assets/sideIcons/all-forms.svg"),
					loading: false,
					options: [],
					children: [],
				},
				{
					label: "Form Templates",
					value: "Form Templates",
					iconUrl: require("@/assets/sideIcons/form.svg"),
					loading: false,
					options: [],
					children: [],
				},
				{
					label: "Survey Templates",
					value: "Survey Templates",
					iconUrl: require("@/assets/sideIcons/survey.svg"),
					loading: false,
					options: [],
					children: [],
				},
				{
					label: "Walk-In Templates",
					value: "Walk-In Templates",
					iconUrl: require("@/assets/sideIcons/walk-in.svg"),
					loading: false,
					options: [],
					children: [],
				},
				{
					label: "Worksheet Templates",
					value: "Worksheet Templates",
					iconUrl: require("@/assets/sideIcons/worksheet.svg"),
					loading: false,
					options: [],
					children: [],
				},
				{
					divider: true,
				},
				{
					label: "Settings",
					value: "Settings",
					iconUrl: require("@/assets/sideIcons/settings.svg"),
					loading: false,
					options: [],
					children: [],
				},
			];
			if (!this.$store.getters.isPremiumPackage) {
				items.splice(4, 1)
			}
			return items;
		},
	},
	methods: {
		selectFormType(data) {
			if (data.value == "All Form/Survey Templates") {
				if (this.$route.path != "/") {
					this.$router.push("/");
				}
			} else if (data.value == "Form Templates") {
				if (this.$route.path != "/forms") {
					this.$router.push("/forms");
				}
			} else if (data.value == 'Survey Templates') {
				if (this.$route.path != "/surveys") {
					this.$router.push("/surveys");
				}
			} else if (data.value == 'Walk-In Templates') {
				if (this.$route.path != "/walk-in") {
					this.$router.push("/walk-in");
				}
			} else if (data.value == 'Worksheet Templates') {
				if (this.$route.path != "/worksheets") {
					this.$router.push("/worksheets");
				}
			} else if (data.value == 'Settings') {
				if (this.$route.path != "/settings") {
					this.$router.push("/settings");
				}
			}
		}
	},
};
</script>
