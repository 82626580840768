<template>
    <div>
        <div
            :class="$style.subbar"
            v-if="instance"
            style="background-color: #f7f5f9"
        >
            <div class="dF aC mb-0">
                <div
                    :class="$style.breadcrumbs"
                    style="align-items: center; display: block !important"
                >
                    <a-breadcrumb>
                        <a-breadcrumb-item>{{
                            instance.name
                        }}</a-breadcrumb-item>
                        <a-breadcrumb-item>{{
                            selectedAppInitial
                        }}</a-breadcrumb-item>
                        <a-breadcrumb-item
                            v-if="$route.params.id"
                            style="color: var(--orange)"
                            >Edit Form/Survey</a-breadcrumb-item
                        >
                        <a-breadcrumb-item
                            v-else-if="$route.path.includes('add')"
                            style="color: var(--orange)"
                            >Add New Form/Survey</a-breadcrumb-item
                        >
                        <a-breadcrumb-item
                            v-else
                            style="color: var(--orange)"
                            >{{ formType }}</a-breadcrumb-item
                        >
                    </a-breadcrumb>
                </div>

                <div :class="$style.amount" class="ml-auto d-none d-sm-flex">
                    <template
                        v-if="
                            !$route.params.id &&
                            !$route.path.includes('add') &&
							!$route.path.includes('settings')
                        "
                    >
                        <a-auto-complete
                            style="width: 200px"
                            placeholder=" Search for a form..."
                            :dropdown-match-select-width="false"
                            :dropdown-style="{ width: '200px' }"
                            option-label-prop="value"
                            @search="handleSearch"
                            class="mr-4"
                            size="large"
                        >
                            <template slot="dataSource">
                                <!-- <a-select-opt-group> -->
                                <a-select-option
                                    @click="optionClicked(form)"
                                    v-for="(form, formI) in searchResult"
                                    :key="form + formI"
                                    :value="form.name + ` (${formI})`"
                                >
                                    <div class="dF aC">
                                        <div class="f1">
                                            <div>
                                                <strong>{{ form.name }}</strong>
                                            </div>
                                            <small class="text-sm">{{
                                                form.type
                                            }}</small>
                                        </div>
                                    </div>
                                </a-select-option>
                                <!-- </a-select-opt-group> -->
                            </template>
                            <a-input>
                                <a-icon
                                    style="stroke: #000; stroke-width: 34"
                                    slot="prefix"
                                    type="search"
                                />
                            </a-input>
                        </a-auto-complete>

                        <div class="mr-4 d-none d-xl-block dash-divider" />
                    </template>

                    <a-button
                        v-if="
                            !$route.params.id &&
                            !$route.path.includes('add') &&
							!$route.path.includes('settings')
                        "
                        @click="$store.commit('SHOW_ADD_FORM')"
                        type="primary"
                        icon="plus"
                        size="large"
                    >
                        ADD NEW
                    </a-button>

                    <template v-else-if="!$route.path.includes('settings')">
                        <a-button
                            @click="$store.commit('PREVIEW_FORM')"
                            size="large"
                            class="mr-3 preview-button"
                        >
                            PREVIEW
                        </a-button>
                        <a-button
                            @click="$store.commit('SAVE_AND_CLOSE')"
                            type="primary"
                            size="large"
                        >
                            SAVE
                        </a-button>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getAppIcon } from "bh-mod";
import SubBarGlobal from "bh-mod/components/layout/SubBarGlobal";
import PageButtons from "bh-mod/components/layout/SubBarGlobal/PageButtons";
const search = {
    leadScore: {
        type: "_gte",
        value: 1,
    },
    firstName: {
        type: "_contains",
        value: "",
    },
    lastName: {
        type: "_contains",
        value: "",
    },
    email: {
        type: "_contains",
        value: "",
    },
    tagI: {
        type: "any",
        value: [],
    },
    tagE: {
        type: "any",
        value: [],
    },
};
export default {
    components: {
        SubBarGlobal,
        PageButtons,
    },
    data() {
        return {
            loadSearch: false,
            search: JSON.parse(JSON.stringify(search)),
            searchResult: [],
        };
    },
    watch: {
        showSearch(val) {
            if (val) {
                let OBJ = JSON.parse(JSON.stringify(search));
                let storedSearch = JSON.parse(
                    JSON.stringify(this.$store.state.forms.searchOBJ)
                );
                if (
                    storedSearch.leadScore &&
                    storedSearch.leadScore.hasOwnProperty("value")
                ) {
                    storedSearch.leadScore.value = parseInt(
                        storedSearch.leadScore.value
                    );
                }

                Object.entries(storedSearch).forEach(([key, value]) => {
                    OBJ[key] = value;
                });
                this.search = OBJ;
            } else {
                this.loadSearch = false;
                this.search = JSON.parse(JSON.stringify(search));
            }
        },
    },
    computed: {
        selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "FSC"
            );
        },
        formSearch() {
            return this.$store.state.search.form;
        },
        formType() {
            return this.$store.state.formType;
        },
        forms() {
            return Object.values(this.$store.state.forms.allForms);
        },
        showSearch() {
            return this.$store.state.forms.showSearch;
        },
        tags() {
            return this.$store.state.forms.tags;
        },
        instance() {
            return this.$store.state.instance;
        },
        theApp() {
            return this.$store.state.theApp || {};
        },
        appInitial() {
            let appinit;
            if (this.theApp.name == "Forms & Survey") {
                appinit = "FSC";
            }
            return appinit;
        },
    },
    methods: {
        optionClicked(data) {
            console.log("OPTION CLICKED", data);
            this.$router.push(`/edit/${data.id}`);
            if (this.formSearch != {}) {
                this.$store.commit("SEARCH_SELECT", {});
            }
            this.$store.commit("SEARCH_SELECT", data);
        },
        handleSearch(input) {
            if (input && input.length > 2) {
                this.searchResult = this.forms.filter(({ name }) => {
                    name = name.toLowerCase();
                    input = input.toLowerCase();
                    return name.includes(input);
                });
            } else {
                return (this.searchResult = []);
            }
        },
        getAppIcon,
        searchContacts() {
            this.loadSearch = true;
            this.$store.commit("SET_SEARCH", this.search);
            this.$store.commit("SET_NEW_SEARCH_TIME");
            this.$store.commit("TOGGLE_SEARCH");
        },
    },
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss">
.ant-form-horizontal.search-horizontal {
    label {
    }
    .ant-form-item-control-wrapper {
        flex: 1;
    }
    .ant-form-item {
        display: flex;
    }
    .ant-form-item-label {
        min-width: 100px;
    }
}
</style>
<style scoped>
.preview-button {
    border: 1px solid var(--orange);
    color: var(--orange);
    background-color: #f6f4f8;
}
.preview-button:hover {
    background-color: var(--orange);
    color: #fff;
}
</style>
