var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%"}},[_c('LeftNavBar',{attrs:{"title":_vm.selectedAppInitial,"hasChild":false,"items":_vm.sidebarItems,"selectedValue":_vm.$route.path == '/'
			? 'All Form/Survey Templates'
			: _vm.$route.path == '/forms'
				? 'Form Templates'
				: _vm.$route.path == '/surveys'
					? 'Survey Templates'
					: _vm.$route.path == '/walk-in'
						? 'Walk-In Templates'
						: _vm.$route.path == '/worksheets'
							? 'Worksheet Templates'
							: _vm.$route.path == '/settings' ? 'Settings' : ''},on:{"opened":_vm.selectFormType}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }