<template>
  <div id="app">
    <MediaSelector />
    <LibrarySelector />
    <localization></localization>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Localization from 'bh-mod/localization'
import MediaSelector from 'bh-mod/components/file/MediaSelector'
import LibrarySelector from 'bh-mod/components/file/LibrarySelector'

export default {
  name: 'app',
  components: { Localization,MediaSelector,LibrarySelector },
  computed: {
    ...mapGetters(['user']),
  },
  watch: {
    '$route'(to, from) {
      const query = Object.assign({}, to.query)
      this.$store.commit('SETUP_URL_SETTINGS', query)
    },
  },
  methods:{

  }
}
</script>
<style>
  #app{
    min-height: 100vh;
  }
</style>
